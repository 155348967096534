import { createSlice } from "@reduxjs/toolkit"
import moment from "moment"

export const AisMVReducer = createSlice({
    name: 'AisMVReducer',
    initialState: {
        error: null,
        isLoading: false,
        AisMVChats: null,
        AisMVMessageList: null,
        AisMvMessagesNotDirected: null,
    },
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = true
        },
        setError: (state, action) => {
            state.isLoading = false,
            state.error = action.payload
        },
        getAisMVChatsSuccess: (state, action) => {
            state.AisMVChats = action.payload,
            state.isLoading = false
        },
        getAisMVMessagesNotDirectedSuccess: (state, action) => {
            state.AisMvMessagesNotDirected = action.payload,
            state.isLoading = false
        },
        getAisMVMessagesListSuccess: (state, action) => {
            const messageList = action.payload
            const messageObj = {}
            if (messageList.length > 0) 
                messageList.forEach(item => {
                    const date = moment(item.sentDate).format('DD.MM.YYYY')
                    if (messageObj.hasOwnProperty(date)) {
                        messageObj[date].push(item)
                    } else {
                        messageObj[date] = [item]
                    }
                })
            state.AisMVMessageList = messageObj,
            state.isLoading = false
        }
    }
})

export default AisMVReducer.reducer