import { createSlice } from "@reduxjs/toolkit";
import downloadFile from '../../../helpers/downloadFile'

export const MessagesReducer = createSlice({
    name: "MessagesReducer",
    initialState: {
        isLoading: false,
        error: null,
        chatList: null,
        created: null,
        messagesList: null,
        isCreateNewChat: false,
        isQuestionRead: false,
        isSentToSpam: false,
        findNewChat: false,
        activeChatId: null,

        createdQuestionToAdmin: null,
        answerToQuestion: null,
        isUpdated: false,
        questionToSpam: null,
        questionsToAdmin: null,
        nestedFiles: null
    },
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = true,
            state.created = null
        },
        setError: (state, action) => {
            state.isLoading = false,
            state.error = action.payload
        },
        setIsSentToSpam: (state, action) => {
            state.isLoading = false,
            state.isSentToSpam = false
        },
        setIsQuestionRead: (state, action) => {
            state.isLoading = false,
            state.isQuestionRead = false
        },
        setIsUpdated: (state, action) => {
            state.isLoading = false,
            state.isUpdated = false
        },
        getChatSuccess: (state, action) => {
            state.chatList = action.payload
            state.isLoading = false
            state.isCreateNewChat ? state.findNewChat = true : null
            state.isCreateNewChat = false 
        },
        getChatMessagesSuccess: (state, action) => {
            state.messagesList = action.payload
            state.isLoading = false
        },
        createMessageSuccess: (state, action) => {
            state.isLoading = false,
            state.created = action.payload ? 'Отправлено' : null
        },
        clearMessagesList: (state) => {
            state.messagesList = null
        },
        setIsCreatedChat: (state) => {
            state.isCreateNewChat = true
        },
        SACI: (state, action) => {
            state.activeChatId = action.payload
        },
        clearFindNewChat: (state) => {
            state.findNewChat = false
        },
        getQuestionsToAdminPagedSuccess: (state, action) => {
            state.isLoading = false
            state.isUpdated = true
            state.questionsToAdmin = action.payload
        },
        getFullQuestionToAdminSuccess: (state, action) => {
            state.isLoading = false,
            state.isQuestionRead = true
            state.fullQuestion = action.payload
        },
        createQuestionToAdminSuccess: (state, action) => {
            state.isLoading = false,
            state.createdQuestionToAdmin = action.payload
        },
        addAnswerToQuestionToAdminSuccess: (state, action) => {
            state.isLoading = false,
            state.answerToQuestion = action.payload
        },
        sendQuestionToSpamSuccess: (state, action) => {
            state.isLoading = false,
            state.isSentToSpam = true
            state.questionToSpam = action.payload
        },
        downloadQuestionToAdminFileSuccess: (state, action) => {
			state.isLoading = false
			state.nestedFiles = action.payload
		},
        clearFile: (state, action) => {
			state.nestedFiles = null
		},
        
    }
})

export default MessagesReducer.reducer