import {
	Link
}from 'react-router-dom'
import {
	Menu, Layout, Row, Col, Dropdown, Button
}from 'antd'
import React, {
	useEffect
}from 'react'
import {
	useDispatch,
	useSelector
}from 'react-redux'
import logo from '../../../public/Logo.svg'
import
iconMenu
	from './icon'
import privateOffice from '../../../public/privateOffice.svg'
import './style.css'
import history from '../../helpers/history'
import {
	userActions
}from '../../store/actions'
const {
	Header
} = Layout

export default () => {
	const dispatch = useDispatch()
	const { user, roles } = useSelector(state => state.userReducer)

	const logOut =() => {
		dispatch(userActions.logout())
	}

	const createAccButton = () => {
			
		return (<>
		<span className='header-username'>
			{ `${ user?.userName }` }
	</span>
	{iconMenu}
	</>)
	}

	return (
		<>
			<Header className="header">
				{ (roles.length === 0) ?
					(<Link
						className="logo_link_main"
						to="/"
					>
						<p>
ИСЭВОРД
						</p>
					</Link>) :
					(<Link
						className="logo_link_main"
						to="/account"
					>
						<p>
ИСЭВОРД
						</p>
					</Link>) }
				{ roles.length>0
					? (<div className="header-menu-item">
						<Menu
							key="0"
							disabledOverflow
							expandIcon={ () => createAccButton() }
							mode="vertical"
						>
							<Menu.SubMenu key="1">
								<Menu.Item key="2">
									<Link to="/account">
Личный кабинет
									</Link>
								</Menu.Item>
								<Menu.Item onClick={ logOut }>
							Выход
								</Menu.Item>
							</Menu.SubMenu>
						</Menu>
					</div>
					)

					: (<Link
						className="accessIcon"
						to="/login"
					   >
						<span className="baseEntry">
                        Вход
				    </span>
						 <img
							alt="office"
							src={ privateOffice }
						 />
					</Link>) }
			</Header>
		</>
	)
}