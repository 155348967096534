import {
	createSlice
}from "@reduxjs/toolkit"
import downloadFile from '../../../helpers/downloadFile'

export const documentsSlice = createSlice({
	name         : 'documentsReducer',
	initialState : {
		isLoading          : false,
		isLoadingAisMVFile : false,
		error              : null,
		sentDocList        : null,
		document           : null,
		docToConfirmList   : null,
		recievedDocList    : null,
		recievedRespList   : null,
		changed            : null,
		sentRespList       : null,
		allDocList         : null,
		allRespList        : null,
		docFile            : null,
		aisMvFile          : null,
		documentFile       : null,
		documentAisMVFile  : null,
		docList            : null
	},
	reducers: {
		setLoading: (state, action) => {
			state.isLoading = true,
			state.changed = null
		},
		setLoadingAisMVFile: (state, action) => {
			state.isLoadingAisMVFile = true,
			state.changed = null
		},
		setLoadingCount: (state, action) => {
			state.isLoadingCount = true
		},
		setError: (state, action) => {
			state.error = action.result
			state.isLoading = false
			state.isLoadingAisMVFile = false
		},
		addSuccess: (state, action) => {
			state.isLoading = false
			state.changed = action.payload ? 'Карточка успешно создана!' : null
		},
		getSentSuccess: (state, action) => {
			state.isLoading = false
			state.sentDocList = action.payload
		},
		getAllDocSuccsess: (state, action) => {
			state.isLoading = false
			state.allDocList = action.payload
		},
		getOtherDocs: (state, action) => {
			state.isLoading = false
			state.docList = action.payload
		},
		getAllRespSuccsess: (state, action) => {
			state.isLoading = false
			state.allRespList = action.payload
		},
		getDtCSuccess: (state, action) => {
			state.isLoading = false
			state.docToConfirmList = action.payload
		},
		getCountSuccess: (state, action) => {
			state.isLoadingCount = false
			state.countList = action.payload
		},
		getDocSuccess: (state, action) => {
			state.isLoading = false
			state.document = action.payload
		},
		getResponseSuccsess: (state, action) => {
			state.isLoading = false
			state.document = action.payload
		},
		confirmSuccsess: (state, action) => {
			state.isLoading = false
			state.changed = action.payload ? 'Документ согласован.' : null
		},
		confirmOpSuccsess: (state, action) => {
			state.isLoading = false
			state.changed = action.payload ? 'Документ отправлен исполнителю.' : null
		},
		sendNpasSuccsess: (state, action) => {
			state.isLoading = false
			state.changed = action.payload ? 'Документ отправлен через НПЭС.' : null
		},
		sendAisMvSuccsess: (state, action) => {
			state.isLoading = false
			state.changed = action.payload ? 'Документ отправлен через АИСМВ.' : null
		},
		rejectSuccess: (state, action) => {
			state.isLoading = false
			state.changed = action.payload ? 'Документ не согласован.' : null
		},
		removeSuccess: (state, action) => {
			state.isLoading = false
			state.changed = action.payload ? 'Документ удален.' : null
		},
		editSuccess: (state, action) => {
			state.isLoading = false
			state.changed = action.payload ? 'Документ отредактирован и направлен на согласование.' : null
		},
		editBySenderSuccess: (state, action) => {
			state.isLoading = false
			state.changed = action.payload ? 'Документ отредактирован.' : null
		},
		completeSuccess: (state, action) => {
			state.isLoading = false
			state.changed = action.payload ? 'Документ завершен.' : null
		},
		responseSuccsess: (state, action) => {
			state.isLoading = false
			state.changed = action.payload ? 'Ответ отправлен' : null
		},
		getRecievedDocSuccess: (state, action) => {
			state.isLoading = false
			state.recievedDocList = action.payload
		},
		getRecievedResponseSuccess: (state, action) => {
			state.isLoading = false
			state.recievedRespList = action.payload
		},
		getSentResponseSuccess: (state, action) => {
			state.isLoading = false
			state.sentRespList = action.payload
		},
		convertSuccsess: (state, action) => {
			state.isLoading = false
			state.docFile = action.payload
		},
		downloadSuccess: (state, action) => {
			state.isLoading = false
			state.documentFile = action.payload
		},
		downloadRespSuccess: (state, action) => {
			state.isLoading = false
			state.documentFile = action.payload
		},
		convertAisMvSuccsess: (state, action) => {
			state.isLoadingAisMVFile = false
			state.aisMvFile = action.payload
		},
		clear: (state, action) => {
			state.changed = null,
			state.aisMvFile = null
		},
		clearFile: (state, action) => {
			state.docFile = null
			state.documentFile = null
		},
		docClear: (state, action) => {
			state.document = null
		},
		downloadAisMVDocumentFile: (state, action) => {
			state.documentAisMVFile = action.payload
			state.isLoading = false
		},
		clearTabs: (state, action) => {
			state.sentDocList = null
			state.docToConfirmList = null
			state.recievedDocList = null
			state.recievedRespList = null
			state.sentRespList = null
		}
	}
})

export default documentsSlice.reducer