const i18n = {
	title: {
		0: "Ошибка аутентификации"
	},
	content: {
		0: "Время сессии истекло. Пожалуйста, повторите вход в личный кабинет."
	}
}

export default i18n

