import React, {
	useEffect
}from 'react'
import {
	Breadcrumb
}from 'antd'
import {
	useLocation, Link
}from "react-router-dom"
import {
	useSelector
}from 'react-redux'
import './style.css'
import Separator from '../../../public/Vector.svg'
import links from './links'

const routes = {
	'nsi'            : links.NSI,
	'users'          : links.users,
	'documents'      : links.documents,
	'documentView'   : links.documentView,
	'messages'       : links.messages,
	'help'           : links.help,
	'oneUser'        : links.oneUser,
	'administration' : links.administration,
	'AISMVMessage'   : links.AISMVMessage,
	'reports'        : links.reports,
	"oneUserCut"     : links.oneUserCut

}

const CustomBreadcrumb = () => {
	const { oneUser } = useSelector(state => state.usersReducer)
	const { roles } = useSelector(state => state.userReducer)
	const location = useLocation()
	const route = location.pathname.split('/').slice(1)
	const isOneUser = route[0] == 'users' && route.length > 1
	const crumbs = isOneUser ? roles?.includes('registeredUser') ? routes.oneUserCut : routes.oneUser : routes[route[0]]

	// const getLink = (x) => {
	// 	switch (route[0]){
	// 		case 'resolution' :
	// 			return x.link(`/appeal/${ resolution?.reOrgAppealId }`)
	// 		case 'appeal' :{
	// 			if (backOptions){
	// 				const redirects = {
	// 					1 : `/appeals/listIn/${ backOptions.matchParamsId }`,
	// 					2 : `/appeals/listYou/${ backOptions.matchParamsId }`,
	// 					3 : `/appeals/listControl/${ backOptions.matchParamsId }`,
	// 					4 : `/appeals/case/${ backOptions.matchParamsId }`,

	// 					// 5 : '/appeals/listAppointYou/9',
	// 					6: '/appeals/collectiveAppeals'
	// 				}
	// 				return x.link(redirects[backOptions.tableIndex])
	// 			}
	// 			return x.link('/appeals')
	// 		}
	// 		case 'appealsRegForm' :{
	// 			return x.link(`/appeal/${ route[1] }`)
	// 		}
	// 		default :
	// 			return null
	// 	}
	// }

	// const user = location.pathname.split('/').slice(1)[0] == 'orgUsers' ? oneOrgUser : oneUser

	return (
		<>
			{ crumbs &&
				<Breadcrumb>
					{ crumbs.map(elem => {
						return (<Breadcrumb.Item
							key="br_item"
							className={ elem.back ? "back" : '' }
						        >
							{
								elem.link ?
									(
										<Link to={ elem.link }>
											{ elem.name }
										</Link>
									)
									: (elem.name)
							}
						</Breadcrumb.Item>)
					}) }
					{ isOneUser &&
						<Breadcrumb.Item
							key="br_item"
						>
							{ oneUser?.userName }
						</Breadcrumb.Item> }
				</Breadcrumb> }
		</>
	)
}
export default CustomBreadcrumb