import Button from "antd/es/button"
import Spin from "antd/es/spin"

import React from "react"

// import "./style.scss"
export const CustomButton = (props) => {
	const {
		children,
		fontSize,
		className,
		spinning = false,
		...otherProps
	} = props

	// const colorType = type ?? ""
	// const sizeType = size ?? "medium"
	const fontSizeType = fontSize ?? "16px"

	return (

		<Button
			{ ...otherProps }
			className={ `custom-button ${ className }` }
		>
			{ children }
		</Button>
	)
}
export default CustomButton
