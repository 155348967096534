import {
	Layout
}from 'antd'
import React, {
	useEffect
}from 'react'
import {
	useDispatch,
	useSelector
}from 'react-redux'
import './style.css'
const {
	Footer
} = Layout

export default () => {
	const dispatch = useDispatch()
	const { user, roles } = useSelector(state => state.userReducer)

	return (
		<>
			{ (roles.length > 0 && roles.includes('registeredUser'))
				?(<Footer className="footer">
					<div className="notification">
						<p>
                        Информацию передавать только контактному лицу и строго в зашифрованном виде. Пароль шифрования необходимо согласовать посредствам личных сообщений системы.
						</p>
					</div>
				</Footer>)
				:null }
		</>
	)
}