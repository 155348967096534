import React from 'react'
const iconMenu = (<svg
	fill="none"
	height="16"
	viewBox="0 0 16 16"
	width="16"
	xmlns="http://www.w3.org/2000/svg"
                  >
	<path
		d="M8.5 8.48984C9.35705 8.48984 10.1949 8.24088 10.9075 7.77445C11.6201 7.30801 12.1755 6.64504 12.5035 5.86939C12.8315 5.09373 12.9173 4.24022 12.7501 3.41679C12.5829 2.59336 12.1702 1.83698 11.5641 1.24332C10.9581 0.64966 10.186 0.245372 9.34539 0.0815806C8.50481 -0.0822103 7.63352 0.00185319 6.84171 0.323141C6.04989 0.644428 5.37312 1.18851 4.89697 1.88658C4.42081 2.58465 4.16667 3.40536 4.16667 4.24493C4.16781 5.3704 4.62473 6.44946 5.43714 7.2453C6.24955 8.04113 7.35108 8.48872 8.5 8.48984ZM8.5 1.41499C9.07137 1.41499 9.62991 1.58096 10.105 1.89192C10.5801 2.20288 10.9503 2.64485 11.169 3.16196C11.3876 3.67906 11.4448 4.24807 11.3334 4.79702C11.2219 5.34598 10.9468 5.85023 10.5428 6.246C10.1387 6.64177 9.62398 6.9113 9.06359 7.0205C8.5032 7.12969 7.92235 7.07365 7.39447 6.85946C6.86659 6.64526 6.41541 6.28254 6.09798 5.81716C5.78054 5.35178 5.61111 4.80464 5.61111 4.24493C5.61111 3.49438 5.91548 2.77457 6.45725 2.24386C6.99902 1.71314 7.73382 1.41499 8.5 1.41499ZM8.5 8.92515C6.77668 8.92702 5.12449 9.59846 3.90592 10.7922C2.68734 11.9859 2.00191 13.6044 2 15.2925C2 15.4802 2.07609 15.6601 2.21153 15.7928C2.34698 15.9255 2.53068 16 2.72222 16C2.91377 16 3.09747 15.9255 3.23291 15.7928C3.36835 15.6601 3.44444 15.4802 3.44444 15.2925C3.44444 13.9791 3.97708 12.7194 4.92518 11.7906C5.87328 10.8619 7.15918 10.3401 8.5 10.3401C9.84082 10.3401 11.1267 10.8619 12.0748 11.7906C13.0229 12.7194 13.5556 13.9791 13.5556 15.2925C13.5556 15.4802 13.6316 15.6601 13.7671 15.7928C13.9025 15.9255 14.0862 16 14.2778 16C14.4693 16 14.653 15.9255 14.7885 15.7928C14.9239 15.6601 15 15.4802 15 15.2925C14.9981 13.6044 14.3127 11.9859 13.0941 10.7922C11.8755 9.59846 10.2233 8.92702 8.5 8.92515Z"
		fill="#2B5CB0"
	/>
</svg>)

export default iconMenu