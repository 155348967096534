import { createSlice } from "@reduxjs/toolkit";

export const NotificationsReducer = createSlice({
    name: "NotificationsReducer",
    initialState: {
        isLoading: false,
        socket: null,
        error: null
    },
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = true
        },
        setError: (state, action) => {
            state.isLoading = false
            state.error = action.payload
        },
        getNotificationsListSuccess: (state, action) => {
            state.isLoading = false,
            state.socket = action.payload
        } 
    }
}) 

export default NotificationsReducer.reducer