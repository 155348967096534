/* eslint-disable sort-keys */
export default {
	NSI: [
		{
			name : 'Личный кабинет',
			link : '/account'
		},
		{
			name : 'НСИ',
			link : '#'
		}
	],
	users: [
		{
			name : 'Личный кабинет',
			link : '/account'
		},
		{
			name : 'Пользователи',
			link : '#'
		}
	],
	oneUserCut: [
		{
			name : 'Личный кабинет',
			link : '/account'
		},
		{
			name : 'Личные данные',
			link : '#'
		}
	],
	oneUser: [
		{
			name : 'Личный кабинет',
			link : '/account'
		},
		{
			name : 'Пользователи',
			link : '/users'
		}
	],
	documents: [
		{
			name : 'Личный кабинет',
			link : '/account'
		},
		{
			name : 'Документы',
			link : '#'
		}
	],
	reports: [
		{
			name : 'Личный кабинет',
			link : '/account'
		},
		{
			name : 'Отчёты',
			link : '#'
		}
	],
	AISMVMessage: [
		{
			name : 'Личный кабинет',
			link : '/account'
		},
		{
			name : 'Документы',
			link : '/documents'
		},
		{
			name : 'Направлено через АИС МВ',
			link : '#'
		}
	],
	messages: [
		{
			name : 'Личный кабинет',
			link : '/account'
		},
		{
			name : 'Сообщения',
			link : '#'
		}
	],
	documentView: [
		{
			name : 'Личный кабинет',
			link : '/account'
		},
		{
			name : 'Документыты',
			link : 'documents'
		},
		{
			name : `Документ № id`,
			link : '#'
		}
	],
	help: [
		{
			name : 'Личный кабинет',
			link : '/account'
		},
		{
			name : 'Вопросы администратору',
			link : '#'
		}
	],
	administration: [
		{
			name : 'Личный кабинет',
			link : '/account'
		},
		{
			name : 'Администрирование',
			link : '#'
		}
	]
}