import React from "react"
import {
	Link
}from "react-router-dom"
import './style.css'

const NotFound = () => {
	return (
		<div className="not-found">
			<h2 className="not-found__title">
Ошибка 404
			</h2>
			<p className="not-found__text">
Страница не найдена. Возможно она устарела или была удалена.
			</p>
			<Link
				className="not-found__link"
				to="/"
			>
Вернуться на главную страницу
			</Link>
		</div>
	)
}

export default NotFound
